html, body {
    height: 100%;
}

body {
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

.padding-0 {
    padding: 0 !important;
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

/* QR code generator */
#qrCode {
    margin: 15px;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
    /* Hide captions */
    .carousel-caption {
        display: none;
    }
}

pre {
    padding: 10px;
    border: solid 1px silver;
}

.keyword {
    color: #0000FF;
}

.type {
    color: #2B91AF;
}

.comment {
    color: green;
}

.quotes {
    color: maroon;
}

.navbar.navbar-inverse {
    background-color: #03249e;
}

.navbar .navbar-brand {
    color: white;
    font-weight: bolder;
}

.navbar .navbar-nav > li > a {
    color: white;
}

.navbar .navbar-nav > li > a:hover {
    color: #9d9d9d;
}

.text-nowrap {
    white-space: nowrap;
}

.no-comments {
    border-radius: 5px;
    background: #D2F4FF;
    padding: 8px 15px;
}

.comment-footer {
    border-radius: 5px;
    background: #D2F4FF;
    margin-top: 10px;
}

.comment-text {
    font-size: 1.2em;
}

.votes-outer {
    height: 48px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 1.2em;
    position: relative;
}

.votes-outer a {
    top: -5px;
    position: relative;
    padding: 5px;
}

.votes {
    width: 48px;
    height: 48px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 24px;
    background: #D2F4FF;
    color: gray;
}

.votes .votes-up {
    color: green;
    font-size: 1.1em;
}

.votes .votes-down {
    color: red;
    font-size: 0.9em;
}

.tile {
    height: 250px;
    /*margin: 0 20px 20px 0;
    padding: 10px;*/
    font-size: 1em;
    /*border: solid 1px whitesmoke;*/
}

.tile h3 {
    font-size: 1.3em;
}

.tile.community {
    background: #EA51FF;
}

.tile.code-issues {
    background: #E84ABE;
}

.tile.ux-for-developers {
    background: #FF5E86;
    /* FFB19C, FFB19C*/
}

.cleaner {
    clear: both;
}

.categories {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
}

.fa-li.category {
    left: 10px;
    color: gray;
}

.latest-questions {
    list-style: none;
    margin: 0 0 0 0px;
    padding: 0 0 0 0px;
    font-size: 1.2em;
}

.latest-questions li {
    border-bottom: dashed 1px silver;
    padding: 10px 5px 10px 5px;
    margin: 0px 0 0 0;
}

.latest-questions li:hover {
    background: whitesmoke;
}

.latest-questions li a {
    display: block;
}

.fa-li.question {
    left: 10px;
    color: gray;
}

.main-content {
    flex: 1 0 auto;
}

.select2-results__option.select2-results__option[aria-selected=true] {
    display: none !important;
}

.info-box {
    margin-top: 25px;
    background: #1E194F;
    color: white;
    list-style: none;
    border: solid 1px silver;
    border-radius: 5px;
    padding: 15px;
}

.info-box .title {
    font-size: 1.2em;
    margin: 8px 0 25px 0;
    font-weight: bolder;
}

.btn-green {
    background: #008A00;
    color: white;
    font-size: 1.2em;
}

.btn-green:hover {
    background: #005400;
    color: white;
}


.single-icon {
    font-size: 3em;
    color: #fb397d;
}

.my-handle {
    cursor: move;
    cursor: -webkit-grabbing;
    font-size: 0.5em;
    border: solid 1px silver;
    background-color: #a8d4e2;
    border-radius: 5px;
    padding: 5px;
    margin: 0 5px 0 0;
}

.sortable-chosen {
    background: #8ae38a;
}


.form-group.block-items .select2-container--default .select2-selection--multiple .select2-selection__choice {
    float: none !important;
}

ul.dropdown-menu li {
    padding: 8px;
}

.input-group-field {
    display: table-cell;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 1%;
    white-space: nowrap;
}

.input-group-field .form-control,
.input-group-field .btn {
    border-radius: inherit !important;
}

.input-group-field:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-field:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.input-group-field:not(:first-child):not(:last-child) .form-control {
    border-left-width: 0;
    border-right-width: 0;
}

.input-group-field:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
