#jiq-search-form {
    border-radius: 5px;
    background: #e8f7ff;
    padding-top: 15px;
    padding-bottom: 15px;
    border: solid 1px #b9daf3;

    select {
        background: whitesmoke;
    }

    .input-group-prepend {
        max-width: 50%;
    }
}