﻿$mastery-level-background-color-0: #323031;
$mastery-level-background-color-1: darkred;
$mastery-level-background-color-2: red;
$mastery-level-background-color-3: orange;
$mastery-level-background-color-4: darkgreen;
$mastery-level-background-color-5: forestgreen;

#mastery-level a {
    margin-right: 5px;
}

#mastery-level a.badge.active {
    color: white;
}

#mastery-level a.active.level1,
#mastery-level a.badge.level1:hover {
    background-color: $mastery-level-background-color-1;
}

#mastery-level a.active.level2,
#mastery-level a.badge.level2:hover {
    background-color: $mastery-level-background-color-2;
}

#mastery-level a.active.level3,
#mastery-level a.badge.level3:hover {
    background-color: $mastery-level-background-color-3;
}

#mastery-level a.active.level4,
#mastery-level a.badge.level4:hover {
    background-color: $mastery-level-background-color-4;
}

#mastery-level a.active.level5,
#mastery-level a.badge.level5:hover {
    background-color: $mastery-level-background-color-5;
}

#mastery-level a.badge {
    background-color: white;
    border: solid 1px #ccc;
    color: #333;
    font-weight: normal;
}

#mastery-level a.badge:hover {
    background-color: #337ab7;
    color: white;
}


.mastery-level-bar {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cc0000+0,f9cc00+48,20f700+100 */
    background: #cc0000; /* Old browsers */
    background: -moz-linear-gradient(left, #cc0000 0%, #f9cc00 48%, #20f700 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #cc0000 0%,#f9cc00 48%,#20f700 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #cc0000 0%,#f9cc00 48%,#20f700 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc0000', endColorstr='#20f700',GradientType=1 ); /* IE6-9 */
}

.badge-info {
    background: #009ad2;
}

.how-it-works-screen {
    display: none;
}

.how-it-works-screen ul li {
    margin-bottom: 10px;
}

.question-list-mastery-progress {
    overflow: hidden;
}

.question-list-mastery-progress.block1 {
    border-radius: 5px;
    border: solid 1px #337ab7;
    height: 20px;
}

.question-list-mastery-progress div {
    height: 100%;
    float: left;
}

.question-list-mastery-progress.block2 {
    border-radius: 0;
    height: 14px;
    border: none;
    margin-top: 5px;
}

.question-list-mastery-progress.block2 div {
    height: 10px;
    float: left;
}

.question-list-mastery-progress.block2 div:hover {
    height: 13px;
    border-bottom: solid 3px silver;
}
