$top-box-bg-color: #7A288F;
$top-box-secondary-bg-color: #AA288F;

.top-box {
    background: $top-box-bg-color;
    font-family: 'PT Sans', sans-serif;
    color: white;
}

.top-box .block1 {
    font-weight: bold;
    font-size: 1.2em;
    padding: 6px 4px 0 4px;
    text-transform: uppercase;
}

.top-box .block2 {
    font-size: 0.9em;
    padding: 3px 0 6px 0;
}

.top-box.secondary {
    background: $top-box-secondary-bg-color;
}

.top-box.secondary .block1 {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    font-family: 'PT Sans', sans-serif;
}

.top-box.secondary .block2 {
    color: white;
    font-size: 0.9em;
    font-family: 'PT Sans', sans-serif;
    padding: 3px 0 6px 0;
}