﻿.console {
    font-family: Courier;
    color: #CCCCCC;
    background: #000000;
    border: 1px solid #002152;
    padding: 10px;
}

    .console:before {
    }

.code {
    font-family: Courier;
    color: #11101a;
    border: 1px solid #002152;
    padding: 10px;
    margin: 15px 0;
}