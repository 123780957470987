.modal-info .modal-header {
    background-color: #009ad2;
}

.modal-info .modal-header .modal-title {
    color: white;
    font-weight: bold;
}

.modal-info .modal-header .close {
    color: white;
    opacity: 0.9;
}

.modal-info .modal-footer {
    padding: 12px;
    text-align: center;
}

.modal-full {
    min-width: 100%;
    margin: 0;
    position: relative;

    .modal-content {
        min-height: 100vh;
        border: none;
        border-radius: 0;
    }

    .modal-footer {
        bottom: 0;
        position: absolute;
        width: 100%;
    } 
}

.modal.full {
    .modal-dialog {
      min-width: 100%;
      margin: 0;
      position: relative;
  
      .modal-content {
          min-height: 100vh;
          border: none;
          border-radius: 0;
      }

      .modal-footer {
          bottom: 0;
          position: absolute;
          width: 100%;
    } 
  }
}