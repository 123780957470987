@import 'bootstrap-bc';
@import '../components/core/_core';
@import '../components/forms/_forms';
@import '../components/footer/_footer';
@import '../components/_ckeditor';
@import '../components/_index-promo';
@import '../components/_navbar';
@import '../components/_modal';
@import '../components/_answer';
@import '../components/_badge';
@import '../components/_notification';
@import '../components/menu/_top-box';
@import '../components/go-to-top/_go-to-top';
@import '../components/questions/_jiq-categories';
@import '../components/questions/_jiq-listing-card';
@import '../components/questions/_jiq-search';
@import '../components/_mastery-level';
@import '../components/_animation-progress';
@import '../components/_prism';
@import '../components/_promo-html-course-lp';
@import '../components/_test-lp';
@import '../components/_test-lp-report';
@import '../components/_test-resume';
@import '../../node_modules/@ng-select/ng-select/scss/default.theme';
@import '../assets/cdn/fontawesome';
@import '../assets/cdn/select2.min.css';
@import '../assets/cdn/bootstrap-datetimepicker.css';
@import '../assets/cdn/css.css?family=PT+Sans';
@import '../../node_modules/summernote/dist/summernote-bs4.min';

$spacer: 1rem;

.mt-1 {
  margin-top: ($spacer * 0.25) !important;
}

.mt-2 {
  margin-top: ($spacer * 0.5) !important;
}

.mt-3 {
  margin-top: ($spacer) !important;
}

.mt-4 {
  margin-top: ($spacer * 1.5) !important;
}

.mt-5 {
  margin-top: ($spacer * 3) !important;
}

.mb-1 {
  margin-bottom: ($spacer * 0.25) !important;
}

.mb-2 {
  margin-bottom: ($spacer * 0.5) !important;
}

.mb-3 {
  margin-bottom: ($spacer) !important;
}

.mb-4 {
  margin-bottom: ($spacer * 1.5) !important;
}

.mb-5 {
  margin-bottom: ($spacer * 3) !important;
}

.pt-1 {
  padding-top: ($spacer * 0.25) !important;
}

.pt-2 {
  padding-top: ($spacer * 0.5) !important;
}

.pt-3 {
  padding-top: ($spacer) !important;
}

.pt-4 {
  padding-top: ($spacer * 1.5) !important;
}

.pt-5 {
  padding-top: ($spacer * 3) !important;
}

.pb-1 {
  padding-bottom: ($spacer * 0.25) !important;
}

.pb-2 {
  padding-bottom: ($spacer * 0.5) !important;
}

.pb-3 {
  padding-bottom: ($spacer) !important;
}

.pb-4 {
  padding-bottom: ($spacer * 1.5) !important;
}

.pb-5 {
  padding-bottom: ($spacer * 3) !important;
}

.app {
  h1 {
    min-height: 44px;

    a {
      order: 1;
      margin-right: 15px;
    }

    span {
      order: 2;
    }
  }
}

@include media-breakpoint-between(xs, md) {
  h1 {
    font-size: 1.2rem !important;
  }

  h2 {
    font-size: 1.35rem !important;
  }

  .app {
    h1 {
      color: white;
      background: #007bff;
      border-bottom: solid 1px #007bff;
      padding: 0.4rem 0.5rem;
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }

  .app .cke_1 {
    border-right: 0 !important;
    border-left: 0 !important;
  }
}

@include media-breakpoint-up(lg) {
  .app h1 {
    color: white;
    background: #007bff;
    border-bottom: solid 1px #007bff;
    padding: 0.4rem 0.8rem;
    margin-top: 0;
    display: flex;
    align-items: center;
  }

  .app h1 .btn-circle {
  }
}

.container.body-content .outlet {
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-block {
  max-width: 980px;
  margin: auto;

  &.app {
    &.wide {
      max-width: 1780px;
    }
  }
}

.btn.btn-circle {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px !important;
  text-align: center;
  font-size: 12px;
}

.btn.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1;
}

.btn.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

// Spinner
.spinner {
  width: 24px;
  height: 24px;
  display: inline-block;
  animation: spinner-conttent-animation 2s linear infinite;
}

$colors: #84ebbd, #4977ec, #f6bb67, #333841;
$d: 175.6449737548828;

.spinner svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(-90deg);

  @for $i from 1 through 4 {
    &:nth-child(#{$i}) circle {
      stroke: nth($colors, $i);
      stroke-dasharray: 1, 300;
      stroke-dashoffset: 0;
      /* clean-css ignore:start */
      animation: spinner-stroke-animation 3s calc(0.2s * (#{$i})) ease infinite;
      /* clean-css ignore:end */
      transform-origin: center center;
    }
  }
}

@keyframes spinner-stroke-animation {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: calc(-#{$d} / 3);
  }

  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d;
  }
}

@keyframes spinner-conttent-animation {
  100% {
    transform: rotate(360deg);
  }
}

input.ng-valid,
input.ng-valid.required {
}

.display-validation .ng-select.ng-invalid .ng-select-container,
.display-validation input.ng-invalid,
.display-validation textarea.ng-invalid {
  border-color: orangered;
  background-color: #fff0f0;
}

.display-validation input.ng-valid,
.display-validation textarea.ng-valid {
  border-color: #28a745;
}

.display-validation .invalid-feedback {
  display: block;
}

.validation-summary {
  margin-bottom: 15px;
  position: relative;

  .badge {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    position: relative;
    top: 2px;
  }

  ul {
    list-style-type: none;
    padding: 3px;
    margin: 0;
    border: solid 1px #ffc107;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    li {
      background-color: white;
      color: #ce0000;
      font-weight: bold;
      font-size: 0.8em;
      padding: 3px 12px;
    }
  }
}

.table-list {
  border-top: solid 1px #cccccc;

  .item {
    border-bottom: solid 1px #cccccc;
    background: #fcfcfc;
  }

  i {
    color: #007bff;
  }
}

@media screen and (max-width: 991.98px) {
  .mobile-fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 0.5em;
    background-color: $deep-dark;
    z-index: 2000;

    .btn.btn-primary {
      background-color: transparent;
      border: white 1px solid;
      color: white;

      &:hover {
        background: darken($deep-dark, 10);
      }
    }
  }
}

.dot {
  height: 130px;
  width: 130px;
  background-color: #315300;
  border-radius: 50%;
  display: inline-block;
  color: white;
  border: 10px solid rgba(55, 232, 15, 0.8);
}

.circle {
  height: 25px;
  width: 25px;
  background-color: rgba(55, 232, 15, 0.8);
  border-radius: 50%;
  margin-bottom: 10px;
}

.test-tile {
  flex-basis: 100%;
  @include media-breakpoint-up(sm) {
    flex-basis: 50%;
  }

  @include media-breakpoint-up(md) {
    flex-basis: 33%;
  }

  .tile-content {
    margin: 5px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 3px 3px 18px 0px #c5d3e5;
  }
}

.notifications-container {
  background: white;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;

  @include media-breakpoint-up(lg) {
    top: 56px;
  }
}

// .note-editor .dropdown-toggle::after {
//   display: none;
// }
.modal-backdrop {
  z-index: 1049 !important;
}