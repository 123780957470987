$custom-file-text: ();

@import '../components/bootstrap/_variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
$btn-padding-x-xs: 0.2rem !default;
$btn-padding-y-xs: 0.12rem !default;
$input-btn-line-height-xs: 1.1 !default;

.btn.btn-xs {
  // line-height: ensure proper height of button next to small input
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $font-size-sm, $input-btn-line-height-xs, $btn-border-radius-sm);
}

.navbar-nav .nav-item.active {
  background: #080808;
}

.navbar {
  padding: 0 !important;
  min-height: 40px;
  line-height: 40px;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

@include media-breakpoint-between(xs, md) {
  .navbar {
    /*min-height: 35px;
    line-height: 35px;*/
  }

  .navbar .nav-link {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.navbar-expand-lg .navbar-nav.left .nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  min-height: 40px;
  line-height: 40px;
}

.modal-header {
  color: white;
  background-color: #009ad2;
  border-bottom: 1px solid #eee;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.close {
  color: white !important;
  text-shadow: none !important;
  opacity: 1 !important;
}

.navbar-toggler {
  padding: 0.1rem 0.4rem !important;
}

.navbar-toggler:focus {
  outline: none;
}

@include media-breakpoint-between(xs, md) {
  .navbar-collapse {
    position: absolute;
    border-top: solid 1px $primary !important;
    top: 49px;
    left: -100%;
    width: 100%;
  }

  .navbar-collapse.collapsing {
    height: auto;
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    transition: left 0.3s ease;
    left: -100%;
  }

  .navbar-collapse.show {
    left: 0;
    -webkit-transition: left 0.3s ease-out;
    -o-transition: left 0.3s ease-out;
    -moz-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
    border-top: $primary;
  }

  .navbar-dark .navbar-toggler-icon {
    background-image: none;
  }

  .navbar-toggler.collapsed #menu-toggle #hamburger {
    transform: rotate(0deg);
    width: 100%;
    left: 0;
  }

  .navbar-toggler #menu-toggle #hamburger {
    transform: rotate(90deg);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    width: 0;
    left: 15px;
  }

  #menu-toggle {
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
    margin: 0px auto;
    position: relative;
    cursor: pointer;

    &:hover {
      /*background: rgba(255,255,255,.8);*/
    }

    #hamburger {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 3px 4px;

      span {
        display: block;
        height: 2px;
        width: 100%;
        position: relative;
        top: 2px;
        left: 0px;
        margin: 4px 0;
        background: white;
      }
    }

    #cross {
      position: absolute;
      height: 100%;
      width: 100%;
      transform: rotate(45deg);

      span {
        background: white;

        &:nth-child(1) {
          height: 0;
          width: 3px;
          position: absolute;
          top: 5px;
          left: 14px;
          transition-delay: 0.25s;
        }

        &:nth-child(2) {
          width: 0;
          height: 3px;
          position: absolute;
          left: 4px;
          top: 15px;
          transition-delay: 0.25s;
        }
      }
    }
  }

  .navbar-toggler #menu-toggle #cross {
    span {
      &:nth-child(1) {
        height: 23px;
      }

      &:nth-child(2) {
        width: 23px;
      }
    }
  }

  .navbar-toggler.collapsed #menu-toggle #cross {
    span {
      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }
  }
}

a.hdr-btn,
button.hdr-btn {
  border: none;
  background: none;
  display: inline-block;
  line-height: 35px;
  padding-right: 4px !important;
  padding-left: 4px !important;
  color: white;
}

a.hdr-btn > span,
button.hdr-btn > span {
  font-weight: bold;
  background: $deep-dark;
  border: solid 1px #426cad;
  border-radius: 5px;
  padding: 0px 8px;
  display: block;
  text-align: center;
}

a.hdr-btn:hover > span,
button.hdr-btn:hover > span {
  background: $deeper-dark;
  color: white;
}

@include media-breakpoint-up(lg) {
  .navbar-nav .nav-link i {
    display: none;
  }
}

@include media-breakpoint-between(xs, md) {
  .navbar-nav.profile {
    margin-top: 10px;
    padding: 1rem 0 !important;
    background: #001b7d;
  }

  .navbar-nav .nav-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  a.hdr-btn,
  button.hdr-btn {
    margin: 5px 0;
    display: block;
    width: 100%;
  }

  a.hdr-btn > span,
  button.hdr-btn > span {
  }
}

.top-box .bc-close {
  background: none;
  border: none;
  color: $light;
  padding: 0 1rem;
}
