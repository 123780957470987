.test-lp {
    .flex.col-xs-2 {
        float: left;
        width: 18%;
        min-width: 130px;
    }

    .step-1 {
        overflow: hidden;
    }
}