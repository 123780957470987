﻿footer {
  z-index: -1;
  padding-top: 20px;
  flex-shrink: 0;
  width: 100%;
  background: #080808;
  color: white;

  .row.block-1 h1,
  .row.block-1 p {
    text-align: center;
  }

  .row.block-1 h1 {
    margin-top: 35px;
  }

  ul {
    margin-left: 0;
    padding-left: 30px;
  }

  ul li a,
  footer ul li a:hover,
  footer ul li a:visited {
    color: #ccc;
  }

  .inner {
  }

  .bottom-stripe {
    background: #1c1c1c;
  }

  .links1 h3,
  .links2 h3 {
    margin-top: 0;
    padding-top: 0;
  }

  .links2 .btn {
    margin-bottom: 20px;
  }

  .links2 .btn i {
    margin-right: 3px;
    color: #03249e;
  }

  .newsletter .well {
    background: none;
    margin-bottom: 0;
  }

  .year .row {
    padding: 10px 0;
  }

  .year a {
    color: white;
  }
}
