/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

figure.code-example {
    padding: 15px;
    margin: 25px 0;
    background: #2D2D30;
    border-radius: 5px;
    box-shadow: 3px 3px gray;
}

    figure.code-example figcaption {
        font-size: 1.5em;
        font-weight: bold;
        color: white;
    }

        figure.code-example figcaption strong {
            font-weight: bolder;
        }

    figure.code-example pre {
        font-size: 1.1em;
        background: #1E1E1E;
    }

code[class*="language-"],
pre[class*="language-"] {
    color: white;
    background: #1E1E1E;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

    pre [class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection, code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
        text-shadow: none;
        background: #1B7BC5;
    }

    pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
    code[class*="language-"]::selection, code[class*="language-"] ::selection {
        text-shadow: none;
        background: #1B7BC5;
    }

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    background: #2D2D30;
    opacity: 0.95;
    font-size: 1.1em;
    padding: 0.1em 0.2em;
    border-radius: .3em;
    white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.function-name,
.token.constant,
.token.symbol,
.token.deleted {
    color: #55FFFF;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #FF2AFF;
}

.token.operator,
.token.entity,
.token.url,
.token.variable,
.language-css .token.string,
.style .token.string {
    color: #9a6e3a;
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #FFD455;
}

.token.function,
.token.class-name {
    color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}
