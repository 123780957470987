.latest-questions {
  .jiq-listing-card {
    border: solid 1px #b9daf3;
    border-radius: 5px;
    background-color: #e8f7ff;
    margin: 0 0 10px 0;
    padding: 5px 15px 15px 18px;
    display: block;
    h2 a:hover {
      text-decoration: none;
    }
    &:hover {
      border: solid 1px #459adc;
      background-color: white;
    }
    .question-number {
      font-size: 0.7em;
      font-weight: bold;
      color: #00438c;
    }
    .question-title {
      font-size: 1.5rem;
    }
  }
}
