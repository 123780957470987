.tag,.code {
    text-align: center;
    
}




.test-resume {
    .overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 168px;
        left: 0;
        overflow: hidden;
        z-index: 1000;
        background: rgba(209, 32, 150, 0.5);

        .overlay-content {
            height: 100%;
            margin: 0 0px;
            padding-top: 20px;

            background: #0048fe; /* Old browsers */
            background: -moz-radial-gradient(center, ellipse cover, #0048fe 0%, #004ab8 56%, #002152 99%); /* FF3.6-15 */
            background: -webkit-radial-gradient(center, ellipse cover, #0048fe 0%,#004ab8 56%,#002152 99%); /* Chrome10-25,Safari5.1-6 */
            background: radial-gradient(ellipse at center, #0048fe 0%,#004ab8 56%,#002152 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0048fe', endColorstr='#002152',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


            .box-panel {
                background: rgba(0, 0, 0, 0.6);
                padding: 0;
                margin: 20px;
                color: white;
                border-radius: 5px;
                overflow: hidden;
   
                .row.title {
                    background: darkslategray;
                    margin: 0 0 20px 0;
                }
            }
        }
    }

    .btn.btn-secondary-outline {
        background: white;
        color: black;
    }
    
    .mb-1 {
        margin-bottom: 20px;
    }
}