﻿.index-promo {
  /*background: #fff !important;*/
}

.index-promo h1,
.index-promo h2 {
  color: #007bff;
  font-weight: bold;
}

.index-promo .divider-line {
  height: 3px;
  width: 80px;
  margin: 0 auto 0px auto;
  background: #fb397d;
}

.index-promo .sub-title {
  margin: 10px 0 40px 0;
}

.rounded-box {
  border: solid 1px #eff2f6;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background: white;
}

.index-promo h4 {
  color: #5b32b4;
  font-weight: normal;
}

#index-header {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0048fe+0,004ab8+56,002152+99 */
  background: #0048fe; /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #0048fe 0%, #004ab8 56%, #002152 99%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, #0048fe 0%, #004ab8 56%, #002152 99%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    #0048fe 0%,
    #004ab8 56%,
    #002152 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0048fe', endColorstr='#002152',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

// #index-header div.container {
//     padding-top: 50px;
//     padding-bottom: 50px;
// }

#index-header div.container h1 {
  color: white;
  font-weight: 600;
  font-size: 2.1em;
}

#index-header div.container h1 strong {
  font-weight: 750;
}

#index-header div.container p {
  color: white;
  font-size: 1.3em;
  margin-bottom: 40px;
}

#index-header div.container .btn {
  text-transform: uppercase;
  color: white;
  background: green;
  font-size: 0.85em;
  margin-bottom: 25px;
}

#index-header div.container .btn:hover {
  background: #03249e;
}

#index-header div.container i.fa {
  margin-right: 4px;
  color: yellow;
}
