$go-to-top-size: 40px;

.go-to-top {
    display: inline-block;
    height: $go-to-top-size;
    width: $go-to-top-size;
    border-radius: 50%;
    text-align: center;

    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    padding: 7px;
    background-color: #161e2c;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.05);
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .3s 0s,visibility 0s .3s;
    transition: opacity .3s 0s,visibility 0s .3s
}

.go-to-top:hover {
    color: #fff;
    background-color: #03249e;
}

.go-to-top.is-visible, .go-to-top.fade-out {
    -webkit-transition: opacity .3s 0s,visibility 0s 0s;
    transition: opacity .3s 0s,visibility 0s 0s
}

.go-to-top.is-visible {
    visibility: visible;
    opacity: 1
}

.go-to-top.fade-out {
    opacity: .5
}

.go-to-top.fade-out:hover {
    opacity: 1;
    color: #fff
}

.go-to-top span {
    display: none
}

@media only screen and (min-width:768px) {
    .go-to-top {
        right: 20px;
        bottom: 20px
    }
}

@media only screen and (min-width:1024px) {
    .go-to-top {
        height: 80px;
        width: 80px;
        right: 30px;
        bottom: 30px;
        padding: 7px
    }

    .go-to-top span {
        display: block;
        text-transform: uppercase;
        line-height: 17px;
        font-weight: bold;
        font-size: 13px
    }
}