.answer {
    border-radius: 5px;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin: 3px;
    border: solid 1px whitesmoke;
}

.answer.available {
    background-color: green;
}

.answer.unavailable {
    background-color: red;
}