$banner-bg-color: #141526;

.html-course-lp {
    .block-1 {
        /*background: $banner-bg-color;*/
        -webkit-background: linear-gradient(270deg, rgba(104,75,241,1.0), rgba(125,82,249,1.0));
        -ms-background: linear-gradient(270deg, rgba(104,75,241,1.0), rgba(125,82,249,1.0));
        -moz-background: linear-gradient(270deg, rgba(104,75,241,1.0), rgba(125,82,249,1.0));
        -o-background: linear-gradient(270deg, rgba(104,75,241,1.0), rgba(125,82,249,1.0));
        background: linear-gradient(270deg, rgba(104,75,241,1.0), rgb(98, 59, 209));
        
        position: relative;
        color: white;

        .highlighted {
            color: white;
            display: inline-block; 
            background: #d301b0;
            padding: 5px 8px;
        }

        .capital {
            text-transform: capitalize;
        }

        h1 {
            color: antiquewhite;
            font-weight: bold;
        }

        p {
            margin-top: 18px;
            font-size: 1.5em;
            line-height: 1.5em;
        }
    }

    @media only screen and (max-width: 768px) {
        .row.block-1 {
            padding-bottom: 15px;
        }

        .row.block-1 h1,
        .row.block-1 p {
            text-align: center;
        }

        .row.block-1 h1 {
            margin-top: 35px;
        }
    }

    .s-curve {
        fill: rgba(104,75,241,1.0);
    }

    .block-1b {
        background: rgba(104,75,241,1.0);
    }

    .block-transition-1-2 {
        background: #161e2c;

        .arrow {
            fill: rgba(104,75,241,1.0);
        }
    }

    .arrow-container {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
    }


    .block-2 {
        background: #161e2c;
        color: white;

        h2 {
            color:white;

            .highlighted {
                display: inline-block; 
                color: white; 
                background: #d301b0;
                padding: 5px 8px;
            }
        }

        p {
            margin-top: 18px;
            font-size: 1.3em;
            line-height: 1.5em;
            max-width: 800px; 
            margin: 0 auto 20px auto;
        }
    }

    .btn-lgx {
        padding: 1.3rem 28px;
        border-radius: 1.3rem;
        font-size: 2.0em;

        i {
            margin-right: 8px;
            color: rgb(86, 219, 9);
        }
    }

    .block-transition-2-3 {
        background: whitesmoke;

        .arrow {
            fill: #161e2c;
        }
    }

    .block-3 {
        background: whitesmoke;
        font-size: 1.2em;

        h2 {
            color: #101010;
        }

        p {
            font-size: 1.1em;
            max-width: 800px; 
            margin: 0 auto;
        }
    }

    .block-transition-3-4 {
        background: white;

        .arrow {
            fill: whitesmoke;
        }
    }

    .circle {
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 2.5em;
        color: white;
        display: inline-block;
        border-radius: 50%;
    }

    .circle.c1 {
        background: #03249e;
        box-shadow: 0 0 0 5px rgba(200, 200, 200, 0.3);
    }

    .circle.c2 {
        background: #c70707;
        box-shadow: 0 0 0 5px rgba(200, 200, 200, 0.3);
    }

    .circle.c3 {
        background: #e28c05;
        box-shadow: 0 0 0 5px rgba(200, 200, 200, 0.3);
    }
}

@media only screen and (max-width: 1024px) {
    .main-img {
        width: 300px;
    }
}

@media only screen and (min-width: 1024px) {
    .main-img {
        position: absolute;
        left: 25%;
        width: 400px;
        top: 0;
    }
}

.bc-grow {
    transition: all .2s ease-in-out;
}

.bc-grow:hover {
    transform: scale(1.02);
}
