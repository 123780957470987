#notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3px 10px 0 10px;
    z-index: 10140;
}

#notification .alert.alert-success {
    border: solid 2px green;
}

#notification .alert.alert-danger {
    border: solid 2px maroon;
}