.jawn {
    position: relative;
    top: 4px;
    left: 4px;
    background-color: #F8E71C;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.jawn:after, .jawn:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.jawn:after {
    left: -10px;
    top: -5px;
    background-color: #C7C2A6;
    transform-origin: 15px 10px;
    animation: axis 1s linear infinite;
}

.jawn:before {
    left: -25px;
    top: -15px;
    background-color: #009bff;
    transform-origin: 30px 20px;
    animation: axis 2s linear infinite;
}

@keyframes axis {
    0% {
        transform: rotateZ(0deg) translate3d(0, 0, 0);
    }

    100% {
        transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
}