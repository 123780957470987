.test-lp-report {
    background: #141526;
    color: whitesmoke;
    padding: 20px 20px 70px 20px;

    .circle {
        $size: 50px;

        display: block;
        height: $size;
        width: $size;
        line-height: $size;
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        margin: 10px;
        color: navajowhite;
        font-size: 1.1em;
        font-weight: bold;
        border: solid 4px rgba(46, 45, 45, 0.8);
    }

    .circle-lg {
        $size: 200px;

        display: table-cell;
        height: $size;
        width: $size;
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        font-weight: bold;
        color: white;
        font-size: 3.5em;
        margin: 0;
        padding: 0;
        background: #315300;
        border: solid 15px rgba(55, 232, 15, 0.80);
    }


    .circle:hover {
        background: #1a1e21;
    }

    .level0.bg {
        background-color: $mastery-level-background-color-0;
    }

    .level1.bg {
        background-color: $mastery-level-background-color-1;
    }

    .level2.bg {
        background-color: $mastery-level-background-color-2;
    }

    .level3.bg {
        background-color: $mastery-level-background-color-3;
    }

    .level4.bg {
        background-color: $mastery-level-background-color-4;
    }

    .level5.bg {
        background-color: $mastery-level-background-color-5;
    }


    .bar {
        border: solid 4px #67799b;
        background: white;
        height: 26px;
        border-radius: 8px;
        margin-top: 5px;
    }

    .bar div {
        height: 100%;
        background: red;
        border-radius: 5px;
    }
}
